.dropzoneBox {
  /* 
  background: #fff;
  */
  display: inline-block;
  justify-content: space-around;
  padding: 20px;
}

.buttonBox {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.btnBoxVFG {
  padding: 20px;
  display: flex;
  justify-content: space-around;
}

.dropareaBox {
  /* 
background: #fff;
*/
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: space-around;

}
.container {
	width: 100%;
	height: calc(100% - 65px);
	background-color: #FFFFFF;
  overflow: auto;
}

.header {
	width: 100%;
	height: 50px;
	background-color: #20477A;
}

.subtitle {
	width: 90%;
	height: 5%;
  text-align: left;
  font-weight: bold;
  color: #fff;
  letter-spacing: 2px;
  float: left;
  margin-top: 0px;
	margin-left: 25px;
}

.text {
    text-align: left;
    margin: 10px 25%;
}

.wrapper {
  position: relative;
  margin-left: 10%;
}
.loading {
  position: absolute;
  top: 5px;
  left: 71px;
}
.loadingBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.dropzoneAreaBox {
  width: 320px;
  height: 200px;
  background-color: red;
}
.fileTitle {
  font-size: 22px;
}
.fileDesc {
  font-size: 20px;
  line-height: 30px;
}
.container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  margin: 0 10px;
  height: calc(100% - 115px);
  border-radius: 4px;
  background: #ffffff;
}

.left {
  // flex-grow: 1;
  width: 300px;
}

.left_upper {
  height: calc(50% - 32px);
  overflow-y: auto;

}

.middle {
  // flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}

.right {
  // flex-grow: 1;
  width: 220px;
}

.stage_item {
  padding: 5px 5px;
}

.stage_li {
  list-style-type: none;
  text-align: left;
  color: #4F4F4F;
}

ul li:hover {
  //font-weight: 600;
  color: #add8e6;
  cursor: pointer;
}

ul {
  margin-block-start: 0;
  list-style-type: none;
  padding-inline-start: 0;
}

.sub_title {
  background-color: rgb(235,235,235);
  text-align: center;
  padding: 5px;
  font-weight: bold;
  height: 22px;
  //border: 1px solid red;
  // display: flex;
}

.sub_title_key {
  flex-grow: 1;
}

.sub_title_selected {
  position: absolute;
  right: 8px;
}

.step_info {
  white-space: pre;
  text-align: left;
  height: calc(50% - 32px);
  width: 100%;
  overflow-y: auto;
}

@keyframes resize-text {
  0%, 100% {
    color: black;
    font-size: 48px;
  }
  50% {
    color: red;
    font-size: 56px;
  }
}
.action_cost{
  font-size: 48px;
  text-align: center;
  margin-top: 12px;
}
.action_cost_animation{
  animation: resize-text 1s 1;
}


.sub_list {
  height: 260px;
  overflow-y: auto;
}

.sub_list::-webkit-scrollbar {
  display: none
}

.sub_item {
  //height: 30px;
  text-align: center;
  position: relative;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
}

.sub_item:hover {
  background-color: #add8e6;
}

// Add the arrow
.sub_item::after{
  display: inline-block;
  padding-right: 0%;
  position: absolute;
  top: 20%;
  right: 5%;
  content: "";
  height: 7px;
  width: 7px;
  border: solid #bbb;
  border-width: 2px 2px 0 0;
}

//Rotate the arrow
.sub_item::after {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.highlight_item {
  background-color: rgb(2, 173, 2);
}

.sub_item_menu {
  top: 22px;
  left: 0;
  text-align: center;
  position: absolute;
  background-color: rgb(246, 248, 246);
  z-index: 100;
  width: 99%;
  overflow-y: scroll;
  max-height: 180px;
  border-style: solid;
  border-width: 1px;
}

.sub_item_menu_item {
  padding: 5px;
  cursor: pointer;
}

.sub_item_menu_item:hover {
  background-color: #bbb;
}

.btn_box {
  height: 50px;
}


